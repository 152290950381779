import {$, $ as jquery} from './provider';

import 'imports-loader?window.jQuery=jquery!owl.carousel';

import 'owl.carousel/dist/assets/owl.carousel.css';
import '../css/index.css';

import {mainAnimation} from './particles.bundle';


var isVideoPlay = false,
    isVideoPlay2 = false,
    headerHeight = $('.header').height();

function tasksAnimation(settings){
    function init(parent){
        l.tasks = $(parent).find('._task');
    }

    var l = this,
        time = 3500,
        next = function(step){
            return step%l.tasks.length
        };
    l.playing = false;
    l.last_tick = 0;
    init(settings.parent);

    l.play = function() {
        l.tasks.addClass('anim');
        if(!l.playing){
            l.playing = true;
            requestAnimationFrame(l.tick);
        }
    };
    l.tick = function(e) {
        if(l.playing){
            requestAnimationFrame(l.tick);
            if(e - l.last_tick > time){// Если время после прошлого кадра больше чем заданная длительность кадра
                l.last_tick = e; // Перезаписываем время прошлого кадра

                var step = (parseInt(l.last_tick/time)%(l.tasks.length ))%(l.tasks.length);
                l.tasks.each(function(i, e) {
                    let $el = $(e),
                        n = next(step + i);
                    $el.removeClass('num0 num1 num2 num3');

                    $el.addClass('num' + n);
                    if(n === 3){
                        $el.removeClass('show');
                    }else{
                        $el.addClass('show');
                    }
                });
            }
        }
    };
}


var FIRST_SCREEN_ANIMATION = function(){
    var mobile = function(){ return $('.main').width() < 1008 },
        A = new mainAnimation('.animWrapper > canvas', mobile()),
        unset = function(){ A.setActive('all'); },
        setGreen = function(){ A.setActive('green'); },
        setBlue = function(){ A.setActive('blue'); },
        setPink = function(){ A.setActive('pink'); },
        $tagS = $('.tags.tags_s'),
        $tagA = $('.tags.tags_a'),
        $tagB = $('.tags.tags_b');

    $tagS.on('mouseover', setGreen).on('mouseleave', unset);
    $tagA.on('mouseover', setBlue).on('mouseleave', unset);
    $tagB.on('mouseover', setPink).on('mouseleave', unset);

    A.params.onHoverColor = function(c){
        switch(c){
            case 'green':
                $tagS.addClass('hover');
                break;
            case 'blue':
                $tagA.addClass('hover');
                break;
            case 'pink':
                $tagB.addClass('hover');
                break;
        }
    };
    A.params.onMouseLeave = function () {
        $tagS.removeClass('hover');
        $tagA.removeClass('hover');
        $tagB.removeClass('hover');
    };
    window.A = A;

    var onresize = function(){
        if(mobile() !== window.A.mobile){
          window.A.reset();
          window.A = null;
          window.A = new mainAnimation('.animWrapper > canvas', mobile());
        }
    };

    $(window).on('resize', function(){
        clearTimeout(window.resizeTimeout);
        window.resizeTimeout = setTimeout(onresize, 500);
    });
};

$(window).on('load', function(){
  if (window.pageYOffset >= 200 && window.pageYOffset < 1500) {
    isVideoPlay = true;
    $('.nav__item.active').find('video').get(0).play();
  }

  var newHeight = headerHeight - window.pageYOffset;
  if (newHeight < 61) {
    $('.header').addClass('shadow');
    $('.header').height(newHeight);
  }

  FIRST_SCREEN_ANIMATION();
});
$(window).on('scroll', function(){
  var header = $('.header'),
      logo = header.find('.header__img'),
      height = headerHeight,
      newHeight = height - window.pageYOffset;
  if (newHeight < 61) {
    header.addClass('shadow');
  } else {
    header.removeClass('shadow');
  }
  header.height(newHeight);
  if (window.pageYOffset >= 200 && window.pageYOffset < 1500) {
    if (!isVideoPlay) {
      isVideoPlay = true;
      $('.nav__item.active').find('video').get(0).play();
    }
  } else {
    if (isVideoPlay) {
      isVideoPlay = false;
      $('.nav__item.active').find('video').get(0).pause();
    }
  }
   if (window.pageYOffset > 780) {
    if (!isVideoPlay2 && $('#cardVideo').length) {
      isVideoPlay2 = true;
      $('#cardVideo').get(0).play();
    }
  } else {
    if (isVideoPlay2) {
      isVideoPlay2 = false;
      $('#cardVideo').get(0).pause();
    }
  }
});

function scrollToId(blockId) {
  var destination = $(blockId).offset().top;
  $('body, html').animate({scrollTop: destination}, 700);
  return false; 
}

function pointer(action = '') {
  if (action == 'open') {
    $('.pointer').fadeIn();
    $('body').addClass('overflow');
  } else {
    $('.pointer').fadeOut();
    $('.modal').fadeOut();
    $('body').removeClass('overflow');
  }
}

$(document).on('click','.pointer', pointer);

$(document).on('keyup blur','input[type="email"]', function() {
  if($(this).val() != '') {
    var pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i;
    if(pattern.test($(this).val())){
      $(this).closest('.form').removeClass('error');
    } else {
      $(this).closest('.form').addClass('error');
    }
  } else {
    $(this).closest('.form').removeClass('error');
  }
});

$(document).on('click','.navMenu__item', function() {
  var parent = $(this).closest('.nav'),
      activeSection = parent.find('.nav__item.active'),
      activeMenu = parent.find('.navMenu__item.active'),
      nextSection = parent.find('.nav__item[data-name="'+$(this).attr("id")+'"]');
  nextSection.find('video').get(0).currentTime = 0;
  nextSection.find('video').get(0).play();
  activeMenu.removeClass('active');
  activeSection.removeClass('active');
  $(this).addClass('active');
  nextSection.addClass('active');
});

$(document).on('click','.faq__title', function() {
  var parent = $(this).closest('.faq__item');
  parent.toggleClass('open');
});

window.onload = function() {
   $('.tags').addClass('start');
   setTimeout(function() { $('.tags_s').addClass('end')}, 2200);
   setTimeout(function() { $('.tags_a').addClass('end')}, 2700);
   setTimeout(function() { $('.tags_b').addClass('end')}, 3200);
};

function showForm(id) {
  $('.form.active').removeClass('active');
  $('#'+id).addClass('active');
   if (id == 'reg'){
    $('.main__container').height(685);
    if (window.innerWidth <= 767){
      $('.main__container').height(715);
    }
  } else{
    $('.main__container').height (485);
    if (window.innerWidth <= 767){
      $('.main__container').height(545);
    }
  }

}

// $(document).on('click','.btn[type="submit"]', function() {
//   var form = $(this).closest('.form');
//   if (form.find('input[type="email"]').val() == '') {
//     form.addClass('error');
//   }
//   if (!form.hasClass('error')) {
//     form.submit();
//   }
// });

$(document).on('click','.header__menuControls', function() {
  $(this).closest('.header__menu').toggleClass('open');
  $('body').toggleClass('overflow');
});

if (window.innerWidth < 1024) {
  $(document).on('click','.form_email .btn', function(e) {
    e.preventDefault();
    window.location.replace("/auth/signin");
  });

  $('.collage.owl-carousel').owlCarousel({
    // loop:true,
    items: 3,
    margin:0,
    nav:false,
    dots:true,
    dotsEach:true,
    dotData:true,
    responsive: {
      0: {
        dots: true,
        items: 1,
      },
      768: {
        items: 3,
      }
    }
  });
}


$(document).ready(function () {

    let taskAnim = new tasksAnimation({parent: $('._tasks')});

    taskAnim.play();

});